.loginBox {
    text-align: center;
    img {
        display: block;
        margin: 2rem auto;
        height: 3rem;
    }
}

.username {
    position: absolute;
    top: 10px;
    right: 10px;
}

.error {
    color: red;
}