@import 'src/styles/index.scss';

.italic {
    font-style: italic;
}

.tableHeader {
    padding-right: 2rem;
}

.card {
    background-color: $eggshell-light;

    th {
        padding-right: 2rem;
        font-weight: 100;
    }

    td {
        vertical-align: bottom;
    }
}

.thBold {
    th {
        padding-top: 1rem;
        font-weight: bold;
    }
}

.thSpacing {
    th {
        padding-top: 0.5rem;
    }
}

.thBigSpacing {
    th {
        padding-top: 1rem;
    }
}

.thSmallSpacing {
    th {
        padding-top: 0.25rem;
    }
}

.craftLink {
    text-decoration: underline;
}
