.datePickerWrapper {
  display: inline-block;

  h2 {
    font-size: 1.5rem;
  }

  button {
    display: block;
    margin-inline: auto;
  }
}