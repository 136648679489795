@import 'normalize';
@import 'variables';
@import 'common';
@import "@coreui/coreui-pro/scss/coreui";

body {
    background: $background;
    font: $font;
    color: $text-color;
}

a {
    color: $link-color;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $link-color;
    font-weight: bold;
}

$sunrise: #ffe382;
$copperred: #d85b40;
$darkgreen: #0e413b;
$darkbeige: #af9d7f;
$beige: #f2e0b4;
$pink: #f9cdd2;
$turquoise: #9dc3c5;
$pastelgreen: #cfedcf;
$darkred: #cf492a;
$eggshell: #fffdf5;
$eggshell-light: #fffefb;
$eggshell-dark: #faf7ee;

.grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
}