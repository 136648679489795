@import 'src/styles/variables';

.toolbar {
    display: flex;
    margin: 0 0 1rem 0;
}

.resetButton {
    cursor: pointer;
    border: none;
    background-color: white;
    border-radius: $border-radius;
    padding: 1rem;
}

.week {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 0.5rem;
}

.day {
    @include borderradius;
    background-color: white;
    padding: 1rem;
    [class~='dayName']::first-letter {
        text-transform: capitalize;
    }
    [class~='date'] {
        font-size: 1.5rem;
    }
}

.totalCapacity {
    background-color: $color-dark-gray;
}
