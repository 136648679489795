
.datePickerWrapper {

  .rdrDateDisplayWrapper {
    font-size: 1rem;
  }

  .rdrCalendarWrapper {
    background: transparent;
    font-size: 1rem;
  }

  .rdrDateDisplayItem {
    box-shadow: none;
    border: 0;
  }
}