.settings {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.heading {
    font-size: 1.5rem;
    margin-block: 0 16px;
}

.content {
    flex-grow: 1;
}

.buttons {
    > :not(:first-child) {
        margin-left: 8px;
    }
}
