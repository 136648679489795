@import '../../styles/variables';

.day {
  @include borderradius;
  background-color: white;
  padding: 1em;

  [class~='dayName']::first-letter {
    text-transform: capitalize;
  }

  [class~='dayName'] {
    font-size: 0.8rem;
  }

  [class~='date'] {
    font-size: 1rem;
  }
}
