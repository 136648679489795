@import 'src/styles/index.scss';

.details {
    margin-top: 1rem;
}

.orderCard {
    background-color: $eggshell-dark;
}

.chevronContainer {
    background: none;
    margin-block: auto;
    margin-left: auto;
}

.condensedContentContainer {
    display: flex;
    border-width: 0px;
    border-radius: 1rem;
    width: 100%;
    background: none;
    text-align: left;

    &:hover {
        background-color: $eggshell-light;
    }

    :last-child {
        margin-left: auto;
    }
}
