/**
  Colors
 */

// Color definitions
$color-light-gray: #FAFAFA;
$color-dark-gray: #ECECEB;
$color-white: #FFFFFF;
$color-warning: #D85B40;
$color-morgen: #FFE382;
$color-rolig: #cfedcf;
$color-dark-700: #43413B;
$color-dark-900:#14120A;

// Color usages
$color-table-element-focused-border: #32C0C8;
$color-table-header: #E1ECEC;
$background: #fafafa;
$text-color: #14120a;
$link-color: $color-dark-700;
$table-header-color: #f6f6f5;
$success-color: $color-rolig;
$fail-color: #f9cdd2;
$notice-color: #9dc3c5;


/**
  Fonts
 */

$font: 16px sans-serif;

/**
 Responsiveness
 */
$size-small-width: 375px;
$size-medium-width: 1024px;
$size-large-width: 1440px;

@mixin small {
    @media (max-width: #{$size-medium-width - 1px}) {
        @content;
    }
}

@mixin medium {
    @media (min-width: #{$size-medium-width}) and (max-width: #{$size-large-width - 1px}) {
        @content;
    }
}

@mixin large {
    @media (min-width: #{$size-large-width}) {
        @content;
    }
}

/**
 Sizes
 */

$border-radius: .5rem;

/**
  Mixins
 */

@mixin borderradius {
    border-radius: $border-radius;
}

@mixin dropshadow {
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
}

@font-face {
    font-family: 'Agipo';
    src: url('../assets/fonts/agipo_regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Agipo';
    src: url('../assets/fonts/agipo_bold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Agipo';
    src: url('../assets/fonts/agipo_regular_italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}
