.table {
    margin-bottom: 1rem;
    th {
        padding-right: 2rem;
        font-weight: 100;
    }
}

.cardList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
