.mt-1\/2 { margin-top: 8px !important; }
.mb-1\/2 { margin-bottom: 8px !important; }
.ml-1\/2 { margin-left: 8px !important; }
.mr-1\/2 { margin-right: 8px !important; }

.mt-1 { margin-top: 16px !important; }
.mb-1 { margin-bottom: 16px !important; }
.ml-1 { margin-left: 16px !important; }
.mr-1 { margin-right: 16px !important; }

.mt-2 { margin-top: 32px !important; }
.mb-2 { margin-bottom: 32px !important; }
.ml-2 { margin-left: 32px !important; }
.mr-2 { margin-right: 32px !important; }

.ta-l { text-align: left !important; }
.ta-r { text-align: right !important; }
.ta-c { text-align: center !important; }
