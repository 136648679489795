@import 'src/styles/variables';

.weekSelector {
  background-color: white;
  border-radius: $border-radius;
  padding: 1rem;
  margin: 0 1rem 0 0;
  width: 340px;
  display: flex;
  justify-content: space-between;
}

.buttonGroup {
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
}