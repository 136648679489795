.inputTableCell {
    padding: 0 .5rem 0 0 !important;

    input {
        padding-inline: .5rem;
    }
}

.buttonTableCell {
    text-align: right;
}

.button {
    &:last-of-type {
        margin-left: 8px;
    }
}
