@import 'src/styles/variables.scss';

.capacity {
  border: 1px solid $color-light-gray;
  padding: 1rem;
  margin: 1rem 0;
  @include borderradius;
  span {
    display: block;
    margin-top: .5rem;
  }
}
