.page {
    padding: 32px 20px;
}

.pageLead {
    margin-bottom: 32px;
}

.heading {
    font-size: 2rem;
    margin-block: 0;
}

.preamble {
    font-size: 1.25rem;
    margin-block: 16px 0;
}
