.container {
    p:last-child {
        margin-bottom: 0;
    }
}

.inlineSvg {
    margin-right: 0.5rem;
    margin-top: -0.35rem;
    display: inline-block;
}
