@import 'src/styles/variables.scss';

.progressBar {
  height: 1rem;
  background-color: $color-light-gray;
  @include borderradius;
  
  &::after {
    content: attr(data-text);
    display: block;
    text-align: center;
    font-size: .8125rem;
    position: relative;
    top: -1rem;
  }

  span {
    display: block;
    @include borderradius;
    height: 1rem;
    &[data-type='Default'] {
      background: $color-rolig;
    }
    &[data-type='Full'] {
      background: $color-warning;
    }
    &[data-type='Warning'] {
      background: $color-morgen;
    }
    &[data-type='Disabled'] {
      background: $color-dark-gray;
    }
  }
}
